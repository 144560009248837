<template>
  <div class="introduction">
    <div class="box">
      <div class="title">
        <div></div>
        <span>平台简介</span>
      </div>
      <div>
        中国-马来西亚互贸创新综合服务平台在真实贸易场景、贸易主体、贸易行为的背景下，通过先进数字化跨境互贸创新服务平台系统，基于星火链网（国家级区块链底层基础设施）快速连接中国及马来西亚政府（工商、税务、海关、法院等机构）、金融机构（银行、保险、第三方支付等），为中马乃至中国-东盟贸易助力，有效实现互联互通、互信共赢。
      </div>
      <div class="desIconList">
        <div v-for="(item, index) in desIconList" :key="index">
          <img :src="item.img" alt="" />
          <p>{{ item.name }}</p>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>科技赋能 · 链接世界 · 创造价值 · 合作共赢</span>
      </div>
      <div class="science">
        <img src="../assets/introduction/science.png" alt="" />
        <p>
          <span>链接世界：</span
          >基于可信区块链底层平台，快速链接境内/外各方资源，通过平台实现“快速互联，有的连，连的快”，链上互信提高效率。
        </p>
        <p>
          <span>资源聚合：</span
          >快速聚合境内/外政府端、各级监管机构、金融机构及各类服务商资源，促进跨境产业协同，实现互信共享。
        </p>
        <p>
          <span>数据共享：</span
          >基于可信网络为产业端及金融端提供跨境协同数据共享查询服务，交易对手及授信企业的征信查询、贸易背景查询等服务。
        </p>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>为境内 / 外企业提供中、马互贸政策便利</span>
      </div>
      <div class="facilitate">
        <img src="../assets/introduction/facilitate.png" alt="" />
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span class="long">为企业提供国际顶级出口资质认证，提升产品认可度</span>
      </div>
      <div class="approve">
        <p>中国境内首家可以提供马来西亚国际顶级HALAL认证的专业服务平台。</p>
        <div>
          提供国际公认顶级认证标准的准入及认证服务，为企业对马来西亚乃至东盟市场、以及全球清真市场的最高规格准入资质，提高企业境外认可度，帮助企业积极推动相关领域的经济增长。
        </div>
        <div class="setup">
          <div class="setupItem" v-for="(item, index) in setup" :key="index">
            <img :src="item.img" alt="" />
            <div>{{ item.text1 }}</div>
            <div>{{ item.text2 }}</div>
            <div class="circle"></div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>为金融合作伙伴创造价值，合作共赢</span>
      </div>
      <div class="approve">
        <p>基于国家级区块链底层基础设施星火链网。</p>
        <div>
          对接境内/外关、检、汇、税、物流等多渠道数据真实贸易背景下的数据归集实现业务价值。
        </div>
        <div class="imgBox">
          <div class="imgItem" v-for="(item, index) in imgList" :key="index">
            <img :src="item.img" alt="" />
            <span>{{ item.name }}</span>
            <div>{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desIconList: [
        {
          img: require("../assets/introduction/desIcon1.png"),
          name: "中马互贸",
          text: "贸易便利中心",
        },
        {
          img: require("../assets/introduction/desIcon2.png"),
          name: "中马互贸",
          text: "国际认证中心",
        },
        {
          img: require("../assets/introduction/desIcon3.png"),
          name: "中马互贸",
          text: "大数据服务中心",
        },
        {
          img: require("../assets/introduction/desIcon4.png"),
          name: "星火链网",
          text: "海外超级节点",
        },
      ],
      setup: [
        {
          img: require("../assets/introduction/setup1.png"),
          text1: "协助企业完成资质",
          text2: "申请的准备工作",
        },
        {
          img: require("../assets/introduction/setup2.png"),
          text1: "为企业组织相关",
          text2: "HALAL认证培训",
        },
        {
          img: require("../assets/introduction/setup3.png"),
          text1: "邀请HALAL认证考核官",
          text2: "对申请企业进行考核",
        },
        {
          img: require("../assets/introduction/setup4.png"),
          text1: "协助企业定期维护资质",
          text2: "确保资质的有效性",
        },
        {
          img: require("../assets/introduction/setup5.png"),
          text1: "基于可信认证资质",
          text2: "提供贷款服务",
        },
      ],
      imgList: [
        {
          img: require("../assets/introduction/img1.png"),
          name: "客户引流",
          text: "为金融合作伙伴提供企业客户引流，实现线上开户。",
        },
        {
          img: require("../assets/introduction/img2.png"),
          name: "需求对接",
          text: "面向企业提供金融服务的窗口，实现结算、融资需求对接。",
        },
        {
          img: require("../assets/introduction/img3.png"),
          name: "数据共享",
          text: "多方可信数据汇集节点，为金融服务端提供数据支撑。",
        },
        {
          img: require("../assets/introduction/img4.png"),
          name: "交易落地",
          text: "实现中马互贸业务的全流程线上化，提升业务效率。",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  padding-top: .54rem;
}
.box {
  padding: 0 .26rem 0 .28rem;
  margin-top: .3rem;
  margin-bottom: .3rem;
  font-size: .11rem;
  line-height: .21rem;
  .title {
    display: flex;
    margin-bottom: .06rem;
    div {
      width: .03rem;
      height: .18rem;
      background: #2a50b7;
      margin-right: .04rem;
    }
    span {
      font-size: .16rem;
      line-height: .2rem;
    }
  }
  .desIconList {
    display: flex;
    padding: 0 .04rem;
    margin-top: .2rem;
    justify-content: space-between;
    div {
      width: .61rem;
      img {
        width: .53rem;
        height: .53rem;
        margin: 0 auto;
      }
      p {
        font-size: .09rem;
        line-height: .15rem;
        color: #2a50b7;
        text-align: center;
      }
    }
  }
  .science {
    margin-top: .12rem;
    img {
      width: 3.29rem;
      height: 2.23rem;
      margin-bottom: .2rem;
    }
    p {
      margin-bottom: .1rem;
      font-size: .11rem;
      line-height: .21rem;
      span {
        color: #2a50b7;
        font-weight: 900;
      }
    }
  }
  .facilitate {
    margin-top: .08rem;
    img {
      width: 3.26rem;
      height: 1.39rem;
    }
  }
  .long {
    width: 2.73rem;
  }
  .approve {
    margin-top: .12rem;
    font-size: .11rem;
    line-height: .21rem;
    p {
      font-weight: 900;
      color: #2a50b7;
    }
    .setup {
      margin-top: .2rem;
      padding: 0 .38rem 0 .36rem;
      display: flex;
      text-align: center;
      position: relative;
      .setupItem {
        width: .46rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        img {
          width: .28rem;
          height: .16rem;
          margin-bottom: .1rem;
        }
        div {
          font-size: .08rem;
          line-height: .11rem;
          color: #666666;
        }
        .circle {
          width: .04rem;
          height: .04rem;
          background: #dddddd;
          border-radius: 50%;
          position: absolute;
          top: .18rem;
        }
      }
      .line {
        position: absolute;
        width: 3.21rem;
        height: .01rem;
        background: #dddddd;
        left: 0;
        top: .2rem;
      }
    }
  }
  .imgBox {
    margin-top: .1rem;
    display: flex;
    justify-content: space-between;
    .imgItem {
      padding: .1rem .1rem 0;
      width: .78rem;
      height: 1.02rem;
      border: .01rem solid #dddddd;
      border-radius: .1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: .24rem;
        height: .23rem;
      }
      span {
        font-size: .07rem;
        line-height: .05rem;
        color: #666666;
        margin-top: .05rem;
        margin-bottom: .04rem;
      }
      div {
        padding-top: .04rem;
        font-size: .05rem;
        line-height: .1rem;
        color: #666666;
        border-top: .01rem solid #DDDDDD;;
      }
    }
  }
}
</style>