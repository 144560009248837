<template>
  <div class="guideDetail">
    <div class="box">
      <div class="title">
        {{ detail.webTitle }}
      </div>
      <div style="width:100%;height: 30px; font-size: 0.12rem">
        <span style="width: 80px;text-align: right;">发布时间：</span>
        <span> {{ detail.newsTime }}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <br/>
        <span style="width: 80px;text-align: right;">来源：</span>
        <span> {{ detail.newsSource }}</span>
      </div>
      <div style="border-bottom: 1px solid #dddddd;margin-top: 20px; "></div>
      <div class="content">
        <div v-html="detail.context"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.detail = JSON.parse(sessionStorage.getItem("detail"));
    console.log(this.detail);
  },
};
</script>

<style lang="scss" scoped>
.guideDetail {
  padding: 0.65rem 0.21rem 0.5rem;
  min-height: calc(100vh - 2.96rem);
}
.box {
  border: 0.01rem solid #ddd;
  padding: 0.17rem 0.38rem 0.25rem;
  .title {
    font-size: 0.14rem;
    line-height: 0.18rem;
    color: #2a50b7;
    padding-bottom: 0.1rem;
    //border-bottom: 0.01rem solid #ddd;
    text-align: center;
    margin-bottom: 0.15rem;
  }
}
.content {
  font-size: 0.11rem;
  line-height: 0.18rem;
  color: #666666 ;
}
</style>
