<template>
  <div class="layout">
    <div class="header">
<!--      <div class="left">中国-马来西亚 互贸创新综合服务平台</div>-->
      <img class="left" style="width: 60%" src="../assets/home/webTopPic.png" alt="" />
      <van-icon name="wap-nav" @click="show = true" />
    </div>
    <router-view></router-view>
    <div class="footer">
      <div class="addressBox">
        <div class="title">中国 - 马来西亚 互贸创新综合服务平台</div>
        <p>联系地址：山东省青岛市市南区宁夏路288号青岛软件园11号楼A座611-29</p>

        <p>联系邮箱：service@mycntrade.com</p>
      </div>
      <div class="QRCode">
        <div>
          <img src="../assets/layout/QRcode1.png" alt="" />
          <p>爱山东</p>
        </div>
        <div>
          <img src="../assets/layout/QRcode2.png" alt="" />
          <p>微信公众号</p>
        </div>
      </div>
      <div class="filings">
        <div class="box">
          <div>
            <img src="../assets/layout/下载.png" alt="" />
            鲁公网安备 37020202001835号 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp; 鲁ICP备2023021508号-2
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" position="right">
      <div
        v-for="(item, index) in nav"
        :class="active == item.path ? 'active' : ''"
        :key="index"
        @click="toUrl(item)"
      >
        {{ item.name }}
      </div>
    </van-popup>
  </div>
</template>


<!--百度统计-->
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?10f7f41c5bf109e9dd77f718d5f400dc";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>


<script>
export default {
  data() {
    return {
      show: false,
      nav: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "项目背景",
          path: "/introduction",
        },
        {
          name: "业务指南",
          path: "/guide",
        },
        {
          name: "技术赋能",
          path: "/technology",
        },
        {
          name: "联系方式",
          path: "/contactUs",
        },
      ],
      active: "/home",
    };
  },
  mounted() {

    //AI小插件
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://static.chatbotkit.com/integrations/widget/v2.js';
    script.id ="chatbotkit-widget";
    script.setAttribute("data-widget","cloh07t5j00avmk0h9o1nvvt9");
    document.body.appendChild(script);

  },
  created() {
    this.active = this.$route.fullPath;
  },
  methods: {
    toUrl(val) {
      if (this.$route.fullPath == val.path) return;
      this.active = val.path;
      this.$router.push(val.path);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  position: absolute;
  background: #2a50b7;
  align-items: center;
  justify-content: space-between;
  padding: 0.11rem 0.11rem 0.12rem 0.16rem;
  width: 100%;
  z-index: 9;
  .left {
    font-size: 0.16rem;
    font-weight: 800;
    line-height: 0.22rem;
    color: #ffffff;
  }
  .van-icon {
    font-size: 0.32rem;
    color: #ffffff;
  }
}

.footer {
  background: #2d354d;
  padding-bottom: 0.48rem;
  .addressBox {
    padding-top: 0.22rem;
    padding-left: 0.18rem;
    color: #ffffff;
    line-height: 0.16rem;
    font-size: 0.08rem;
    .title {
      font-size: 0.16rem;
    }
    .address {
      display: flex;
      margin-top: 0.05rem;
      margin-bottom: 0.05rem;
    }
    .left {
      width: 0.76rem;
    }
  }
  .QRCode {
    padding: 0 1.17rem;
    margin-top: 0.43rem;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 0.67rem;
        height: 0.67rem;
      }
      p {
        font-size: 0.09rem;
        line-height: 0.14rem;
        margin-top: 0.08rem;
        color: #ffffff;
      }
    }
  }
  .filings {
    margin-top: 0.32rem;
    font-size: 0.09rem;
    line-height: 0.14rem;
    color: #ffffff;
    display: flex;
    justify-content: center;
    .box {
      div {
        display: flex;
        align-items: center;
        img {
          width: 0.1rem;
          height: 0.11rem;
        }
      }
    }
  }
}

.van-popup {
  height: 100%;
  width: 2.43rem;
  div {
    line-height: 0.65rem;
    border-bottom: 0.01rem solid #dddddd;
    padding-left: 0.25rem;
    font-size: 0.19rem;
    color: #999999;
  }
  .active {
    color: #2a50b7;
    font-weight: 800;
    border-bottom: 0.01rem solid #2a50b7;
  }
}
</style>
