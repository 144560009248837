import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Layout from '../components/Layout.vue' // 布局
import Introduction from '../views/Introduction.vue' // 项目背景
import Guide from '../views/Guide.vue' // 业务指南
import GuideDetail from '../views/GuideDetail.vue' // 业务指南详情
import Technology from '../views/Technology.vue' // 技术赋能
import ContactUs from '../views/ContactUs.vue' // 联系我们
import Login from '../views/Login.vue' // 联系我们

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: Home,
        meta: {
          title: '中马互贸',
          content:"中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
        }
      },
      {
        path: "/introduction",
        component: Introduction,
        meta: {
          title: '项目背景',
          content:"中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
        }
      },
      {
        path: "/guide",
        component: Guide,
        meta: {
          title: '业务指南',
          content:"中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
        }
      },
      {
        path: "/guideDetail",
        component: GuideDetail,
        meta: {
          title: '详情',
          content:"中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
        }
      },
      {
        path: "/technology",
        component: Technology,
        meta: {
          title: '技术赋能',
          content:"中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
        }
      },
      {
        path: "/contactUs",
        component: ContactUs,
        meta: {
          title: '联系方式',
          content:"中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
        }
      },
    ]
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: '登录注册'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
